import {
    TextField,
    TabbedShowLayout,
    Tab,
    NumberField,
    TranslatableFields,
    Labeled,
    useRecordContext,
    RichTextField,
    ArrayField,
    Datagrid,
    useLocaleState,
    useTranslate
} from "react-admin";
import { ImageList, ImageListItem, Grid, Typography } from "@mui/material";
import { supportedLocales } from "../../../../data/providers/i18n";
import { ORIGIN } from "../../../../data/common.js";
import noImage from "../../../../assets/images/no_tree_photo.svg";
import UIMapboxField from "../../../ui/fields/UIMapboxField";

const WLocationShowable = () => {
    const record = useRecordContext();
    const [locale] = useLocaleState();
    const translate = useTranslate();
    return (
        <TabbedShowLayout syncWithLocation={false}>
            <Tab label="resources.location.tabs.common">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <TextField source="id" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <NumberField source="area" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled fullWidth source="country">
                            <TranslatableFields locales={supportedLocales}>
                                <TextField source="country" fullWidth />
                            </TranslatableFields>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled fullWidth source="city">
                            <TranslatableFields locales={supportedLocales}>
                                <TextField source="city" fullWidth />
                            </TranslatableFields>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled fullWidth source="title">
                            <TranslatableFields locales={supportedLocales}>
                                <TextField source="title" fullWidth />
                            </TranslatableFields>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled fullWidth source="short_description">
                            <TranslatableFields locales={supportedLocales}>
                                <TextField source="short_description" fullWidth />
                            </TranslatableFields>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled fullWidth source="description">
                            <TranslatableFields locales={supportedLocales}>
                                <RichTextField source="description" fullWidth />
                            </TranslatableFields>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Labeled>
                            <NumberField source="price_extra" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Labeled>
                            <NumberField source="trees_ordered" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Labeled>
                            <NumberField source="trees_planted" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Labeled>
                            <NumberField source="target" fullWidth />
                        </Labeled>
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.location.tabs.images" disabled={!record?.images}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.images?.length > 0 ? (
                            <Labeled source="images">
                                <ImageList variant="masonry" cols={2} gap={8}>
                                    {record.images.map(image => (
                                        <ImageListItem key={image.id}>
                                            <img
                                                src={new URL(image.path, ORIGIN).toString()}
                                                alt=""
                                                loading="lazy"
                                                onError={e => (e.target.src = noImage)}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Labeled>
                        ) : (
                            <Labeled source="images">
                                <Typography variant="subtitle1">
                                    {translate("resources.location.other.noImages")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.location.tabs.geolocation">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        <Labeled source="zone" fullWidth>
                            <UIMapboxField source="zone" type="polygon" />
                        </Labeled>
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.location.tabs.species">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.species?.length > 0 ? (
                            <Labeled label="resources.location.other.linkedSpecies" fullWidth>
                                <ArrayField source="species">
                                    <Datagrid
                                        bulkActionButtons={false}
                                        sx={{
                                            "& .RaDatagrid-headerCell": {
                                                fontWeight: "bold"
                                            }
                                        }}
                                        rowClick={id => `/specie/${id}/show`}>
                                        <TextField source="id" />
                                        <TextField source={`common_name.${locale}`} />
                                        <TextField source={`scientific_name.${locale}`} />
                                        <TextField source="price" />
                                    </Datagrid>
                                </ArrayField>
                            </Labeled>
                        ) : (
                            <Labeled label="resources.location.other.linkedSpecies" fullWidth>
                                <Typography variant="subtitle1">
                                    {translate("resources.location.other.noSpecies")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
        </TabbedShowLayout>
    );
};

export default WLocationShowable;
